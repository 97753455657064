import React from 'react';

export function Legend({
  baseClassName,
}) {
  return (
    <div className={`${baseClassName}__legend legend`}>

      <div className="legend__line">
        <span className="legend__item legend__item_location_exact"/> - exact location
      </div>

      <div className="legend__line">
        <span className="legend__item legend__item_location_estimated"/> - estimated location
      </div>

    </div>
  );
}
