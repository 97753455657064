import { useMemo, useCallback, useState } from 'react';

export function useEnumFilter(items, field) {
  let initial = useMemo(
    () => items.reduce((r, x) => {
      let value = x[field];

      r[value] = true;

      return r;
    }, {}),
    [items, field]
  );

  let [filters, setFilters] = useState(initial);

  let toggle = useCallback(
    value => setFilters({ ...filters, [value]: !filters[value] }),
    [filters, setFilters],
  );

  return [filters, toggle];
}
