import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { useBoolean } from '@entegral/helpers.react/hooks/boolean';
import { useWindowEventWithFlag } from '@entegral/helpers.react/hooks/window-event-with-flag';
import { stopPropagation } from '@entegral/helpers.react/events';

export function Textbox({
  id,
  name,
  value=``,
  type=`text`,
  placeholder,
  autoComplete,

  floatingLabel,
  readOnly,

  baseClassName=`textbox`,
  extraClassName,

  onChange,
  onFocusChange,
}) {
  let onValueChange = useCallback(event => onChange(event.target.value, name), [onChange, name]);

  let [inFocus, setFocus, setBlur] = useBoolean(false);

  let onFocus = useCallback(event => {
    setFocus();

    if (!onFocusChange) return;

    event.target.select();
    onFocusChange(true, name);
  }, [onFocusChange, name, setFocus]);

  let onBlur = useCallback(() => {
    setBlur();

    if (!onFocusChange) return;

    onFocusChange(false, name);
  }, [onFocusChange, name, setBlur]);

  useWindowEventWithFlag('click', onBlur, inFocus);

  return (
    <div className={classnames(baseClassName, extraClassName, {
      [`${baseClassName}_value_not_empty`]: !!value,
      [`${baseClassName}_label_floating`]: floatingLabel,
      [`${baseClassName}_state_readonly`]: readOnly,
    })}>
      <input
        id={id}
        placeholder={!floatingLabel ? placeholder : null}
        value={value}
        readOnly={readOnly}
        type={type}
        autoComplete={autoComplete}
        className={`${baseClassName}__input`}

        onChange={onValueChange}
        onFocus={onFocus}
        onClick={stopPropagation}
      />

      {floatingLabel
        ? (
          <label
            className={`${baseClassName}__label`}
            htmlFor={id}
          >
            {placeholder}
          </label>
        ) : null}
    </div>
  );
}
