import { mapGeolibBoundsToArray, mapGeolibCoordsToArray } from '@entegral/helpers.geolib/map';

export function mutatePinPointsGroupsFromGeolibFormat(pinPointsGroups) {
  pinPointsGroups.forEach(x => {
    x.bounds = x.bounds && mapGeolibBoundsToArray(x.bounds);
    x.coords = mapGeolibCoordsToArray(x.coords);

    x.items.forEach(y => {
      y.coords = mapGeolibCoordsToArray(y.coords);
    });
  });
}
