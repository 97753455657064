import { useMemo, useCallback, useState, useEffect } from 'react';

export function useRangeFilter(items, field) {
  let initial = useMemo(
    () => {
      let values = items.map(x => x[field]);

      if (!values.length) return { min: 0, max: 0 };

      return {
        min: Math.min(...values),
        max: Math.max(...values),
      };
    },
    [items, field]
  );

  let [filters, setFilters] = useState(initial);
  let [isMinChanged, setIsMinChanged] = useState(false);
  let [isMaxChanged, setIsMaxChanged] = useState(false);

  useEffect(() => {
    if (isMinChanged && isMaxChanged) return;

    let state = { ...filters };

    if (!isMinChanged) state.min = initial.min;
    if (!isMaxChanged) state.max = initial.max;

    setFilters(state);
  }, [initial]);

  let setMin = useCallback(value => {
    setFilters({ ...filters, min: value });
    if (!isMinChanged) setIsMinChanged(true);
  }, [filters, setFilters, isMinChanged]);

  let setMax = useCallback(value => {
    setFilters({ ...filters, max: value });
    if (!isMaxChanged) setIsMaxChanged(true);
  }, [filters, setFilters, isMaxChanged]);

  return [filters, setMin, setMax];
}
