import React, { useCallback } from 'react';
import { useBoolean } from '@entegral/helpers.react/hooks/boolean';
import { useDropdownClassName } from '@entegral/helpers.react/hooks/dropdown-classname';
import { useSyncingState } from '@entegral/helpers.react/hooks/syncing-state';
import { useWindowEventWithFlag } from '@entegral/helpers.react/hooks/window-event-with-flag';
import { stopPropagation } from '@entegral/helpers.react/events';
import { PriceTextbox } from '@entegral/components.price-textbox';
import useCaption from './use-caption';

export function RangeDropdown({
  min,
  max,
  onMinChange,
  onMaxChange,
  currency,
  children,
  baseClassName=`range-dropdown`,
  extraClassName,
}) {
  let [isOpen, open, close] = useBoolean(false);
  let className = useDropdownClassName(baseClassName, extraClassName, isOpen);

  let [innerMin, setInnerMin] = useSyncingState(min);
  let [innerMax, setInnerMax] = useSyncingState(max);

  let caption = useCaption(innerMin, innerMax, currency);

  let closeWithCommit = useCallback(() => {
    close();

    if (innerMin !== min) onMinChange(innerMin);
    if (innerMax !== max) onMaxChange(innerMax);
  }, [innerMin, innerMax, min, max, onMinChange, onMaxChange, close]);

  useWindowEventWithFlag('click', closeWithCommit, isOpen);

  return (
    <div
      className={className}
      onClick={isOpen ? closeWithCommit : open}
    >

      <div className={`${baseClassName}__caption`}>
        {caption}
      </div>

      <div
        className={`${baseClassName}__options`}
        onClick={stopPropagation}
      >
        <div className={`${baseClassName}__textboxes`}>
          <PriceTextbox
            value={innerMin}
            onChange={setInnerMin}
            extraClassName={`${baseClassName}__textbox`}
          />

          <span className={`${baseClassName}__textbox-splitter`}>-</span>

          <PriceTextbox
            value={innerMax}
            onChange={setInnerMax}
            extraClassName={`${baseClassName}__textbox`}
          />
        </div>

        {children}

        <div
          className={`${baseClassName}__close`}
          onClick={closeWithCommit}
        >
          Done
        </div>
      </div>
    </div>
  );
}
