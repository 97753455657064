import React from 'react';
import { Marker, Popup } from 'react-leaflet';

export function PinPointGroupDetailedMarkerLeaflet({
  coords,
  details,
  icon,
  baseClassName,
}) {
  return (
    <Marker
      position={coords}
      icon={icon}
    >
      <Popup className={`${baseClassName}__popup`}>
        <div dangerouslySetInnerHTML={{__html: details}}/>
      </Popup>
    </Marker>
  );
}
