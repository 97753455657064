import 'core-js';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import localforage from 'localforage';
import axios from 'axios';

window.React = React;
window.ReactDOM = ReactDOM;
window.Classnames = classnames;
window.LocalForage = localforage;
window.Axios = axios;
