import { useCallback, useState } from 'react';

export function useBoolean(initialValue) {
  let [value, setValue] = useState(initialValue);

  let enable = useCallback(() => setValue(true), []);
  let disable = useCallback(() => setValue(false), []);

  return [value, enable, disable];
}
