import React, { useMemo, useCallback } from 'react';
import { getStatusType } from '../utils/get-status-type';
import { CheckboxList } from '@entegral/components.checkbox-list';

export function StatusFilter({
  baseClassName,
  filter,
  toggle,
}) {
  let options = useMemo(() => {
    let statuses = Object.keys(filter).sort();

    return statuses.map(x => ({
      value: x,
      isChecked: filter[x],
      modifiers: [`type_${getStatusType(x)}`],
    }));
  }, [filter]);

  let toggleItem = useCallback(item => toggle(item.value), [toggle]);

  return (
    <CheckboxList
      options={options}
      onToggleItem={toggleItem}
      extraClassName={`${baseClassName}__filter ${baseClassName}__filter_type_status`}
    />
  );
}
