import { useMemo } from 'react';
import classnames from 'classnames';

export function useDropdownClassName(baseClassName, extraClassName, isOpen) {
  return useMemo(() => classnames(
    baseClassName,
    extraClassName,
    {
      [`${baseClassName}_state_opened`]: isOpen,
    }
  ), [baseClassName, extraClassName, isOpen]);
}
