module.exports = function shortForm(value) {
  if (!value) return 0;

  value = parseInt(value);

  if (isNaN(value)) return '';

  if (value < 1000) return value.toString();

  let suffix;
  let shortenValue;

  if (value < 1000000) {
    suffix = 'K';
    shortenValue = value / 1000;
  } else {
    suffix = 'M';
    shortenValue = value / 1000000;
  }

  let precision = shortenValue / 100 < 1 ? 1 : 0;

  shortenValue = shortenValue.toFixed(precision);
  shortenValue = shortenValue.replace('.0', '');

  return `${shortenValue}${suffix}`;
};
