export const DISTANCES = {
  0: 2400 * 1000,
  1: 2400 * 1000,
  2: 1450 * 1000,
  3: 750 * 1000,
  4: 400 * 1000,
  5: 190 * 1000,
  6: 100 * 1000,
  7: 50 * 1000,
  8: 25 * 1000,
  9: 13 * 1000,
  10: 6500,
  11: 3500,
  12: 1500,
  13: 1000,
  14: 375,
  15: 200,
  16: 100,
  17: 50,
  18: 30,
  19: 0,
};
