import { useMemo, useState, useCallback } from 'react';
import L from 'leaflet';

export function useBounds(initialCoords) {
  const initialBounds = useMemo(() => L.latLngBounds(initialCoords), []);

  const [bounds, setBounds] = useState(initialBounds);

  const fitBounds = useCallback(bounds => {
    setBounds(null);
    setBounds(L.latLngBounds(bounds));
  }, []);

  return [bounds, fitBounds];
}
