import { mapArrayCoordsToGeolib } from '@entegral/helpers.geolib/map';

export function mapPinPointsToGeolibFormat(pinPoints) {
  return pinPoints.map(pinPoint =>
    ({
      ...pinPoint,
      coords: mapArrayCoordsToGeolib(pinPoint.coords),
    })
  );
}
