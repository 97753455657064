import React from 'react';
import classnames from 'classnames';
import { titlecase } from '@entegral/helpers.formatters/text';
import { useEventCallbackWithoutPropagation } from '@entegral/helpers.react/hooks/event-callback-without-propagation';
import { useMemoWithDeps } from '@entegral/helpers.react/hooks/memo-with-deps';

export default function ({
  item,
  item: {
    name,
    value,
    group,
    isChecked,
    isGroup,
    modifiers,
  },
  onToggle,
  baseClassName,
}) {
  let onClick = useEventCallbackWithoutPropagation(
    () => onToggle(item),
    [item, onToggle],
  );

  let modifiersClassNames = useMemoWithDeps(mods => mods.map(mod => `${baseClassName}__option_${mod}`), [], [modifiers]);

  let className = classnames(
    `${baseClassName}__option`,
    {
      [`${baseClassName}__option_type_group`]: isGroup,
      [`${baseClassName}__option_state_checked`]: isChecked,
    },
    ...modifiersClassNames
  );

  return (
    <div
      className={className}
      onClick={onClick}
    >
      <span className={`${baseClassName}__box`} />
      <span className={`${baseClassName}__label`}>
          {titlecase(name || value || group)}
      </span>
    </div>
  );
}
