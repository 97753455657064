import React from 'react';
import { RangeDropdown } from '@entegral/components.range-dropdown';

export function PriceFilter({
  baseClassName,
  filter,
  currency,
  setMin,
  setMax,
}) {
  return (
    <RangeDropdown
      min={filter.min}
      max={filter.max}
      currency={currency}
      onMinChange={setMin}
      onMaxChange={setMax}
      extraClassName={`${baseClassName}__filter ${baseClassName}__filter_type_price`}
    />
  )
}
