import React, { useMemo } from 'react';

import { useDivIcon } from '@entegral/helpers.leaflet/hooks';
import { usePinPointsGroupMarkerClassname } from '../hooks/group-marker-classname';
import { usePinPointsGroupMarkerContent } from '../hooks/group-marker-content';

import { PinPointGroupDetailedMarkerLeaflet } from './group-detailed-marker-leaflet';
import { PinPointGroupSummaryMarkerLeaflet } from './group-summary-marker-leaflet';

export function PinPointGroupMarkerLeaflet({
  group,
  baseClassName,
  fitBounds,
}) {
  let className = usePinPointsGroupMarkerClassname(group, baseClassName);
  let html = usePinPointsGroupMarkerContent(group, baseClassName);

  let iconOptions = useMemo(() => ({ className, html }), [className, html]);
  let icon = useDivIcon(iconOptions);

  return group.isDetailed
    ? (
      <PinPointGroupDetailedMarkerLeaflet
        coords={group.coords}
        details={group.details}
        icon={icon}
        baseClassName={baseClassName}
      />
    ) : (
      <PinPointGroupSummaryMarkerLeaflet
        coords={group.coords}
        bounds={group.bounds}
        icon={icon}
        fitBounds={fitBounds}
      />
    );
}
