import React, { useMemo, useCallback } from 'react';
import { CheckboxList } from '@entegral/components.checkbox-list';

export function TypeFilter({
  baseClassName,
  filter,
  toggle,
}) {
  let options = useMemo(() => {
    let types = Object.keys(filter).sort();

    return types.map(x => ({
      value: x,
      isChecked: filter[x],
    }));
  }, [filter]);

  let toggleItem = useCallback(item => toggle(item.value), [toggle]);

  return (
    <CheckboxList
      options={options}
      onToggleItem={toggleItem}
      extraClassName={`${baseClassName}__filter ${baseClassName}__filter_type_type`}
    />
  );
}
