import { useMemo } from 'react';
import { useBounds } from '@entegral/helpers.leaflet/hooks/bounds';

export function usePinPointsBoundsLeaflet(pinPoints, defaultViewCoords) {
  const coords = useMemo(() => {
    if (defaultViewCoords) return defaultViewCoords.map(x => [x.lat, x.lng]);

    return pinPoints.map(pinPoint => pinPoint.coords);
  }, [pinPoints, defaultViewCoords]);

  return useBounds(coords);
}
