import React, { useMemo } from 'react';
import { shortForm } from '@entegral/helpers.formatters/number';

export default function (min, max, currency) {
  return useMemo(() => {
    if (min === 0) return `Up to ${currency}${shortForm(max)}`;

    return `${currency}${shortForm(min)}-${currency}${shortForm(max)}`;
  }, [min, max, currency]);
}
