import classnames from 'classnames';
import { useMemoIfNotNull } from '@entegral/helpers.react/hooks/memo-if-not-null';

export function usePinPointsGroupMarkerClassname(group, baseClassName) {
  return useMemoIfNotNull(() => classnames(
    `${baseClassName}__marker`,
    group.className,
    {
      [`${baseClassName}__marker_density_low`]: group.count <= 10,
      [`${baseClassName}__marker_density_medium`]: group.count > 10 && group.count <= 50,
      [`${baseClassName}__marker_density_high`]: group.count > 50,
    },
  ), [group, baseClassName]);
}
