import React, { useCallback, useState, useEffect } from 'react';
import { thousandSeparator } from '@entegral/helpers.formatters/number';
import { useMemoWithDeps } from '@entegral/helpers.react/hooks/memo-with-deps';
import { Textbox } from '@entegral/components.textbox';

let thousandSeparatorCleaner = value => value.replace(/,/ig, '');

export function PriceTextbox({
  id,
  name,
  value,
  placeholder,

  floatingLabel,
  readOnly,

  baseClassName=`textbox`,
  extraClassName,

  onChange,
}) {
  let [formatted, setFormatted] = useFormattedNumberState(value, onChange, thousandSeparator, thousandSeparatorCleaner);

  return (
    <Textbox
      id={id}
      name={name}
      value={formatted}
      placeholder={placeholder}

      floatingLabel={floatingLabel}
      readOnly={readOnly}

      baseClassName={baseClassName}
      extraClassName={[`${baseClassName}_content_price`, extraClassName]}

      onChange={!readOnly ? setFormatted : null}
    />
  );
}

function useFormattedNumberState(value, setValue, formatter, cleaner) {

  let [inner, setInner] = useState(value == undefined ? '' : value.toString());

  useEffect(() => {
    let parsed = parseFloat(inner);

    if (parsed !== value) setInner(value.toString());
  }, [value]);

  let formatted = useMemoWithDeps(() => {
    let formatted = formatter(inner);

    if (inner.includes('.') && !formatted.includes('.')) {
      formatted += `.${inner.split('.')[1]}`;
    }

    return formatted;
  }, inner, [inner, formatter]);

  let setFormatted = useCallback(value => {
    let cleaned = cleaner ? cleaner(value) : value;
    let parsed = parseFloat(cleaned);

    if (isNaN(parsed)) {
      setInner('0');
      setValue(0);
    } else {
      setInner(cleaned);
      setValue(parsed);
    }
  }, [setValue, cleaner]);

  return [formatted, setFormatted];
}
