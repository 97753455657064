import { mergePinPointsWithinDistance } from './merge-within-distance';
import { getMergeDistance } from '@entegral/helpers.leaflet/utils';

export function composeGroups(pinPoints, zoom, hydrateGroupStrategy, hydrationContext) {
  let mergeDistance = getMergeDistance(zoom);
  let groups = mergePinPointsWithinDistance(pinPoints, mergeDistance);

  groups.forEach(x => {
    x.isDetailed = isDetailedGroup(x);
    hydrateGroupStrategy(x, zoom, hydrationContext);
  });

  return groups;
}

function isDetailedGroup(group) {
  let isCollapsedBounds = (
    group.bounds
    && group.bounds[0][0] === group.bounds[1][0]
    && group.bounds[0][1] === group.bounds[1][1]
  );

  let isSingleArea = group.items.length === 1;

  return isCollapsedBounds || isSingleArea;
}
