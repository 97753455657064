import React, { useCallback } from 'react';
import { Marker } from 'react-leaflet';

export function PinPointGroupSummaryMarkerLeaflet({
  coords,
  bounds,
  icon,
  fitBounds,
}) {
  let onClick = useCallback(() => fitBounds(bounds), [bounds, fitBounds]);

  return (
    <Marker
      position={coords}
      icon={icon}
      onClick={onClick}
    />
  );
}
