import { useState, useEffect } from 'react';

export function useSyncingState(state) {
  let [inner, setInner] = useState(state);

  useEffect(() => {
    if (state !== inner) setInner(state);
  }, [state]);

  return [inner, setInner];
}
