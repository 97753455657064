import { getDistance } from 'geolib';

export function findPinPointsNearby(sourcePinPoint, candidatePinPoints, radius) {
  function isWithinDistance(candidatePinPoint) {
    let distance = getDistance(sourcePinPoint.coords, candidatePinPoint.coords);

    if (isNaN(distance)) distance = 0;

    return distance <= radius;
  }

  return candidatePinPoints.filter(isWithinDistance);
}
