import { useMemo } from 'react';

export default function (options) {
  return useMemo(() => {
    let activeOptions = options.filter(x => x.isChecked && !x.isGroup);

    if (activeOptions.length === options.length) return `All`;
    if (activeOptions.length === 0) return `None`;

    return activeOptions.map(x => x.name || x.value).join(', ');
  }, [options]);
}
