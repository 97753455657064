import React from 'react';
import ReactDOM from 'react-dom';
import { rootNode } from '@entegral/helpers.react/settings/root-node';
import { componentData } from '@entegral/helpers.react/settings/component-data';

import { PropertiesReviewMap } from './components/properties-review-map';

ReactDOM.render(
  <PropertiesReviewMap {...componentData} />,
  rootNode
);
