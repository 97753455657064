import React from 'react';
import { TileLayer as TileLayerLeaflet } from 'react-leaflet';

export function TileLayer({
  accessToken,
  tilesId=`mapbox/streets-v11`,
}) {
  return (
    <TileLayerLeaflet
      url={`https://api.mapbox.com/styles/v1/${tilesId}/tiles/{z}/{x}/{y}?access_token=${accessToken}`}
      attribution="&copy; <a href='https://www.openstreetmap.org'>OpenStreetMap</a> | © <a href='https://www.mapbox.com'>Mapbox</a>"
    />
  )
}
