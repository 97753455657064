import { getCenter, getBounds } from 'geolib';
import { difference } from 'lodash';
import { mapPinPointsToGeolibFormat } from '../utils/map-to-geolib-format';
import { mutatePinPointsGroupsFromGeolibFormat } from '../utils/mutate-groups-from-geolib-format';
import { findPinPointsNearby } from './find-nearby';

export function mergePinPointsWithinDistance(pinPoints, mergeDistance) {
  let geolibPinPoints = mapPinPointsToGeolibFormat(pinPoints);

  let groups = createGroups(geolibPinPoints, mergeDistance);

  mutatePinPointsGroupsFromGeolibFormat(groups);

  return groups;
}

function createGroups(pinPoints, mergeDistance) {
  let groups = [];
  let notMerged = pinPoints;

  while (notMerged.length) {
    let pinPoint = notMerged.shift();
    let group;

    [group, notMerged] = createGroup(pinPoint, notMerged, mergeDistance);

    groups.push(group);
  }

  return groups;
}

function createGroup(pinPoint, notMerged, mergeDistance) {
  let nearby = findPinPointsNearby(pinPoint, notMerged, mergeDistance);

  let group = { coords: pinPoint.coords, items: [pinPoint] };

  if (nearby.length) {
    let coords = [group.coords, ...nearby.map(x => x.coords)];

    group.coords = getCenter(coords);
    group.bounds = getBounds(coords);
    group.items.push(...nearby);
  }

  notMerged = difference(notMerged, nearby);

  return [group, notMerged];
}
