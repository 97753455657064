import { useEffect } from 'react';

export function useWindowEventWithFlag(eventName, handler, flag) {
  useEffect(() => {
    if (!flag) return;

    window.addEventListener(eventName, handler);

    return () => window.removeEventListener(eventName, handler)
  }, [eventName, handler, flag]);
}
