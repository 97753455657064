import React from 'react';
import { useBoolean } from '@entegral/helpers.react/hooks/boolean';
import { useDropdownClassName } from '@entegral/helpers.react/hooks/dropdown-classname';
import { useWindowEventWithFlag } from '@entegral/helpers.react/hooks/window-event-with-flag';
import useCaption from './use-caption';
import Option from './option';

export function CheckboxList({
  options,
  onToggleItem,
  children,
  baseClassName=`checkbox-list`,
  extraClassName,
}) {
  let [isOpen, open, close] = useBoolean(false);
  let caption = useCaption(options);
  let className = useDropdownClassName(baseClassName, extraClassName, isOpen);

  useWindowEventWithFlag('click', close, isOpen);

  return (
    <div
      className={className}
      onClick={isOpen ? close : open}
    >

      <div className={`${baseClassName}__caption`}>
        {caption}
      </div>

      <div className={`${baseClassName}__options`}>
        {options.map(option =>
          <Option
            key={option.value}
            item={option}
            onToggle={onToggleItem}
            baseClassName={baseClassName}
          />
        )}

        {children}

        <div
          className={`${baseClassName}__close`}
          onClick={close}
        >
          Done
        </div>
      </div>

    </div>
  );
}
